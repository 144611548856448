import React from "react"
import {graphql} from "gatsby"

import '../../css/custom.css'
import Layout from "../../components/layout_fr"
import {Container, Row, Col, Button} from "react-bootstrap"
import { IoChevronForwardOutline } from "@react-icons/all-files/io5/IoChevronForwardOutline";
import Seo from "../../components/seo"
import {GatsbyImage} from "gatsby-plugin-image";
import logoTrust from "../../images/logo-bar-customers.png";
import Slider from "react-slick";
//import tree from "../../images/tree.svg";
//import { useLocalization } from "gatsby-theme-i18n"
//import { useIntl, Link, FormattedMessage } from "gatsby-plugin-react-intl"

//import { GatsbyImage } from "gatsby-plugin-image";

/*import Image from "../components/image"
import SEO from "../components/seo"*/

const IndexPage = ({data}) => {



    return(



        <Layout>
            <Seo
                title="Logiciel de gestion de trésorerie qui va vous simplifier la vie"
                lang="fr"
            />
            <Container>

                {data.allStrapiHomeSection1.edges.map(section1 => (
                    <Row className="home-box-header justify-content-center" key={section1.node.id}>
                        <Col className="my-auto home-box-text order-2 order-sm-1 order-lg-1">

                            <h1 className="title">{section1.node.Title}</h1>
                            <p className="section">{section1.node.Description}</p>

                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/fr/demande-demo/">{section1.node.TextButton1}</Button>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>{section1.node.TextButton2}</Button>
                                </Col>
                            </Row>

                        </Col>

                        <Col className="home-box-image order-1 order-sm2 order-lg-2 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {/*  {section1.node.video &&

                            <video className="box-shadow" key={section1.node.video.id} src={section1.node.video.url} width="100%" height="100%" loop muted playsInline autoPlay></video>
                        }*/}

                            {section1.node.MainImage?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} className="" key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>

                    </Row>
                ))}
            </Container>

            <Container>
                <Col className="pt-5">
                    <Row className=" center justify-content-center">
                        <h2 className="title-trust">Rejoignez les clients qui nous font confiance</h2>
                    </Row>
                    <Row>
                        <Slider lazyLoad={"progressive"} arrows={false} touchMove={false} dots={false} pauseOnHover={false} infinite={true} slidesToShow={1} slidesToScroll={1} autoplay={true} speed={40000} autoplaySpeed={0} cssEase={"linear"}>
                            <div>

                                <img
                                    alt=""
                                    variant="top"
                                    width="100%"
                                    src={logoTrust}
                                />

                            </div>
                            <div>
                                <img
                                    alt=""
                                    variant="top"
                                    width="100%"
                                    src={logoTrust}
                                />

                            </div>
                        </Slider>
                    </Row>
                </Col>
                <Col>

                </Col>

            </Container>

            <Container>

                {data.allStrapiHomeSection2.edges.map(section2 => (

                    <Row className="home-box justify-content-center" key={section2.node.id} >

                        <Col className='my-auto home-box-image order-1 order-sm-1 order-lg-1' xs={12} sm={12} md={6} lg={6} xl={6} >
                            {section2.node.MainImage?.map( image=> (
                                <GatsbyImage key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}
                        </Col>

                        <Col className=" my-auto home-box-text order-2 order-sm-2 order-lg-2" >
                            <h2 className="title">{section2.node.Title}</h2>

                            <p className="section">{section2.node.Description1}</p>

                            <p className="section">{section2.node.Description2}.</p>

                            {/*   <Row className="pt-4">
                            <Col className="left justify-content-center pt-1" xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Button variant="demo"  style={{width:"100%"}} href="/fr/demande-demo">Demandez une démo</Button>
                            </Col>
                        </Row>*/}



                        </Col>
                    </Row>

                ))}

            </Container>

            <Container className="container-collab">
                {data.allStrapiHomeSection7.edges.map(section7 => (
                    <Row key={section7.node.id} className="home-box-1 justify-content-center">

                        <Col className="my-auto home-box-text order-2 order-sm-1 order-lg-1" >
                            <h2 className="title">Un suivi de trésorerie efficace</h2>

                            <p className="section-h2">La centralisation de vos transactions financières vous procure des données cohérentes et fiables issues directement de vos comptes bancaires et vos applications métiers.</p>
                            <p className="section-h2">Les mises à jour de vos tableaux de trésorerie sont automatiques, vous n'avez donc plus à vous soucier de savoir si votre feuille de calcul affiche les bons chiffres ou si vos formules fonctionnent correctement.</p>
                            <p className="section-h2">Cela vous permet de vous concentrer davantage sur l’essentiel : l’analyse, et la prise de décision.</p>


                            <Row className="pt-4 center">
                                <Col className="left justify-content-center mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <Button variant="link-kom" style={{width:"100%"}} className="ensavoirplus" href="/fr/fonctionnalites/suivi-de-tresorerie/">En savoir plus <IoChevronForwardOutline></IoChevronForwardOutline></Button>
                                </Col>
                            </Row>

                        </Col>



                        <Col className='my-auto home-box-image order-1 order-sm-1 order-lg-1' xs={12} sm={12} md={6} lg={6} xl={6} >
                            <GatsbyImage key={section7.node.video.id} image={section7.node.video.localFile.childImageSharp.gatsbyImageData} alt=""/>
                        </Col>

                    </Row>
                ))}
            </Container>

            <Container className="">
                {data.allStrapiHomeSection3.edges.map(section3 => (
                    <Row key={section3.node.id} className="home-box-1 justify-content-center">
                        <Col  className="my-auto home-box-image order-1 order-sm-1 order-lg-1">
                            {section3.node.MainImage?.map( image=> (
                                <GatsbyImage key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}
                        </Col>

                        <Col className="my-auto home-box-text order-2 order-sm-2 order-lg-2" xs={12} sm={12} md={6} lg={6} xl={6}>
                            <h2 className="title">Un prévisionnel de trésorerie plus sûr</h2>

                            <p className="section-h2">Avec Trezorino, vous allez pouvoir anticiper vos besoins de financement, planifier vos investissements au bon moment et visualiser l'évolution de votre trésorerie.</p>
                            <p className="section-h2">Les prévisions et les scénarios de trésorerie vous aident à projeter l'avenir en continu et à réagir efficacement aux événements inattendus.</p>




                            <Row className="pt-4">
                                {/*<Col className="left justify-content-center mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Button variant="demo" style={{width:"100%"}} className="" href="/fr/demande-demo">Demandez une démo</Button>
                            </Col>*/}
                                <Col className="left justify-content-center mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <Button variant="link-kom" style={{width:"100%"}} className="ensavoirplus" href="/fr/fonctionnalites/previsions-de-tresorerie/">En savoir plus <IoChevronForwardOutline></IoChevronForwardOutline></Button>
                                </Col>
                            </Row>

                        </Col>


                    </Row>
                ))}
            </Container>

            <Container className="container-blue">
                {data.allStrapiHomeSection4.edges.map(section4 => (
                    <Row key={section4.node.id} className="home-box-1 justify-content-center">

                        <Col className="my-auto home-box-text order-2 order-sm-1 order-lg-1" >
                            <h2 className="title">Maîtrisez vos coûts et améliorez vos marges</h2>

                            <ul className="check">
                                <li><p className="section-h2">Planifiez vos dépenses pour éviter des défauts de trésorerie qui peuvent ralentir votre développement.</p></li>
                                <li><p className="section-h2">Identifiez immédiatement où et comment vous devez agir en analysant facilement vos dépassements de budget.</p></li>
                                <li><p className="section-h2">Réduisez vos coûts en faisant jouer la concurrence grâce à une vision claire de l'évolution de vos dépenses sur plusieurs mois.</p></li>
                            </ul>


                            <Row className="pt-4">
                                <Col className="left justify-content-center mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <Button variant="demo" style={{width:"100%"}} className="" href="/fr/demande-demo/">Demandez une demo</Button>
                                </Col>
                            </Row>

                        </Col>

                        <Col  className="my-auto home-box-image order-1 order-sm-2 order-lg-2" xs={12} sm={12} lg={6} xl={6} md={6}>
                            {section4.node.MainImage?.map( image=> (
                                <GatsbyImage key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}
                        </Col>

                    </Row>
                ))}
            </Container>

            <Container className="">
                {data.allStrapiHomeSection5.edges.map(section5 => (
                    <Row key={section5.node.id} className="home-box-1 justify-content-center">
                        <Col  className="my-auto home-box-image order-1 order-sm-1 order-lg-1">
                            <GatsbyImage key={section5.node.video.id} image={section5.node.video.localFile.childImageSharp.gatsbyImageData} alt=""/>
                        </Col>

                        <Col className="my-auto home-box-text order-2 order-sm-2 order-lg-2" xs={12} sm={12} md={6} lg={6} xl={6}>
                            <h2 className="title">Planifiez votre stratégie de croissance</h2>

                            <p className="section-h2">Identifiez les compétences dont vous avez besoin pour répondre à une augmentation future de votre activité. Construisez votre plan de recrutement et anticipez son coût.</p>
                            <p className="section-h2">Vous avez besoin de nouveaux équipements pour la réalisation de vos projets ? Exécutez vos achats et vos demandes de financements au bon moment.</p>

                            <Row className="pt-4">
                                <Col className="left justify-content-center mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <Button variant="demo" style={{width:"100%"}} className="" href="/fr/demande-demo/">Demandez une démo</Button>
                                </Col>
                            </Row>

                        </Col>


                    </Row>
                ))}
            </Container>

            <Container className="container-collab">
                {data.allStrapiHomeSection6.edges.map(section6 => (
                    <Row key={section6.node.id} className="home-box-1 justify-content-center">

                        <Col className="my-auto home-box-text order-2 order-sm-1 order-lg-1" >
                            <h2 className="title">Une trésorerie collaborative</h2>

                            <ul className="check">
                                <li><p className="section-h2">Renforcez la culture cash en mobilisant l'effort collectif.</p></li>
                                <li><p className="section-h2">Construisez vos prévisions avec les acteurs clés de votre entreprise et agissez efficacement sur votre trésorerie.</p></li>
                                <li><p className="section-h2">Partagez vos idées, vos documents, et gardez vos collaborateurs informés en temps réel.</p></li>
                            </ul>


                            <Row className="pt-4">
                                <Col className="left justify-content-center mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <Button variant="link-kom" style={{width:"100%"}} className="ensavoirplus" href="/fr/fonctionnalites/tresorerie-collaborative/">En savoir plus <IoChevronForwardOutline></IoChevronForwardOutline></Button>
                                </Col>
                            </Row>

                        </Col>

                        <Col  className="my-auto home-box-image order-1 order-sm-2 order-lg-2" xs={12} sm={12} lg={6} xl={6} md={6}>
                            {section6.node.video?.map( vid=> (
                                <video key={vid.id} src={vid.url} width="100%" height="100%" loop muted playsInline autoPlay></video>
                            ))}
                        </Col>

                    </Row>
                ))}
            </Container>


            <Container className="">
                {data.allStrapiHomeSection8.edges.map(section8 => (
                    <Row key={section8.node.id} className="home-box-1 justify-content-center">
                        <Col  className="my-auto home-box-image order-1 order-sm-1 order-lg-1">
                            <GatsbyImage key={section8.node.video.id} image={section8.node.video.localFile.childImageSharp.gatsbyImageData} alt=""/>
                        </Col>

                        <Col className="my-auto home-box-text order-2 order-sm-2 order-lg-2" xs={12} sm={12} md={6} lg={6} xl={6}>
                            <h2 className="title">Une gestion de trésorerie qui s'adapte à toutes les organisations</h2>

                            <p className="section-h2">Trezorino offre la possibilité de gérer les flux de trésorerie à tous les niveaux d'une entreprise, qu'il s'agisse d'une entité, d’une agence, d'un département ou d'un projet.</p>
                            <p className="section-h2">Il permet à chacun de bénéficier de données financières centralisées en temps réel, de réaliser des prévisions, d'élaborer des plans d’actions et de simuler les impacts de stratégies spécifiques.</p>


                            <Row className="pt-4">
                                <Col className="left justify-content-center mt-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <Button variant="demo" style={{width:"100%"}} className="" href="/fr/demande-demo/">Demandez une démo</Button>
                                </Col>
                            </Row>

                        </Col>


                    </Row>
                ))}
            </Container>


            <Container className="container-support">


                {data.allStrapiHomeSection1.edges.map(teams => (
                    <Row key={teams.node.id} className="home-box-1 justify-content-center">

                        <Col className="my-auto home-box-text order-2 order-sm-1 order-lg-1" >
                            <h2 className="title">Vous ne serez jamais seuls.</h2>

                            <ul className="check">
                                <li><p className="section">Vos premiers pas sont assurés par une formation complète.</p></li>
                                <li><p className="section">Vous pouvez contactez nos équipes à tout moment par téléphone, chat ou mail.</p></li>
                                <li><p className="section">Un expert trésorerie dédié qui vous accompagne tout au long de votre parcours.</p></li>
                            </ul>

                        </Col>

                        <Col   className="my-auto home-box-image order-1 order-sm-2 order-lg-2" xs={12} sm={12} lg={6} xl={6} md={6}>

                            {teams.node.video &&
                                <GatsbyImage key={teams.node.video.id} image={teams.node.video.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            }


                            {/*  <picture >

                        <source srcSet={teams} media="(max-width: 480px)"></source>


                        <source  srcSet={teams} media="(max-width: 768px)"></source>


                        <source  srcSet={teams} media="(max-width: 992px)"></source>

                        <img  className="image1" src={teams} alt="" style={{border: "0,1px solid #E9E9E9"}}/>

                    </picture>*/}

                        </Col>

                    </Row>
                ))}
            </Container>

            <Container className="align-items-center justify-content-center pb-8 pt-8">
                <Row className="home-box-1 justify-content-center">
                    <Col classname=" my-auto home-box-text order-1 order-sm-1 order-lg-1 pb-5">
                        <h2 className="center title">Vous voulez voir Trezorino en action ?</h2>
                        <Row   className="center justify-content-center">
                            <Button variant="demo"  className="home-last-button" href="/fr/demande-demo/">Demandez une démo</Button>
                        </Row>

                    </Col>
                </Row>
            </Container>

        </Layout>

    )
}

export default IndexPage

export const data = graphql`  
query home {
  allStrapiHomeSection1 {
    edges {
      node {
        id
        Title
        Description
        ShowButton1
        TextButton1
        ShowButton2
        TextButton2
        video {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE, width:700)    
            }
          }
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE, width:700)  
            }
          }
        }
      }
    }
  }
  allStrapiHomeSection2 {
    edges {
      node {
        id
        Title
        Description1
        Description2
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiTrustLogos {
    edges {
      node {
        id
        Logo {
          id
          url
           localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiTitleTrustLogo {
    edges {
      node {
        id
        Title
      }
    }
  }
  allStrapiHomeSection3 {
    edges {
      node {
        id
        Title
        SubTitle
        Description
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiHomeSection3Bullets {
    edges {
      node {
        id
        Order
        Text
      }
    }
  }
  allStrapiHomeSection4 {
    edges {
      node {
        id
        Description
        Title
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiHomeSection5 {
    edges {
      node {
        id
        video {
          url
          id
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiHomeSection6 {
    edges {
      node {
        id
        video {
          url
          id
        }
      }
    }
  }
  allStrapiHomeSection7 {
    edges {
      node {
        id
        video {
          url
          id
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiHomeSection8 {
    edges {
      node {
        id
        video {
          url
          id
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
}
`
